import React, { useEffect, useState } from "react";
import "./Css.css";
import styled from "styled-components";
import img from "../Page1/whaLe.png";
import { motion } from "framer-motion";
import theme from "styled-theming";
import i from "./Elements.svg";
import { FcPlus } from "react-icons/fc";
import { FaMinusCircle } from "react-icons/fa";
// import { useWallet } from 'use-wallet'
import { useDispatch, useSelector } from "react-redux";
import { Trr } from "../Page2/Page2";
import Web3 from "web3";
import ABI from "../../BlockchainProvider/abi/abis.json";
import config from "../../BlockchainProvider/config";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import {apiGetAccountNonce,apiGetGasPrices} from "../../BlockchainProvider/api"
import BigNumber from 'bignumber.js'



export function convertAmountToRawNumber(
  value,
  decimals=18
){
  return new BigNumber(`${value}`)
    .times(new BigNumber('10').pow(decimals))
    .toString()
}


const providerOptions = {
  /* See Provider Options Section */
  walletconnect: {
    package: WalletConnectProvider,
    options: {
      infuraId: "20NW4m1fVikhFodQ6XA3prwU5cL",
    },
  },
};





const web3Modal = new Web3Modal({
  network: "mainnet", // optional
  cacheProvider: true, // optional
  providerOptions, // required
});

export const backgroundColor = theme("theme", {
  light: "#000000",
  dark: "#E5E5E5",
});

const Sec = styled.section`
  min-height: 100vh;
  width: 100%;
  overflow-x: hidden !important;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-image: radial-gradient(
    circle,
    #000000,
    #0d0d0d,
    #161617,
    #1d1d1e,
    #232526
  );
  @media only screen and (max-width: 768px) {
    min-height: 100vh;
    padding: 2rem 0;

    justify-content: space-around;
  }
`;

const Width = styled.div`
  width: 1700px;
  display: flex;

  align-items: center;
  justify-content: space-between;
  @media only screen and (max-width: 1900px) {
    width: 97%;
  }
  @media only screen and (max-width: 1400px) {
    width: 99%;
  }
  @media only screen and (max-width: 1100px) {
    flex-direction: column;
  }
`;

const Left = styled.div`
  margin-right: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 50%;
  @media only screen and (max-width: 1100px) {
    width: 95%;
  }
  @media only screen and (max-width: 768px) {
    margin-right: 0;
    width: 100%;
    padding: 0 1rem;
    height: auto;
  }
`;

const Right = styled.div`
  margin-left: 2rem;
  display: flex;
  width: 50%;
  flex-direction: column;

  @media only screen and (max-width: 1100px) {
    width: 95%;
    justify-content: center;
  }
  @media only screen and (max-width: 768px) {
    width: 100%;
    height: auto;
    margin-left: 0;
    padding: 0 1rem;
    align-items: center;
  }
`;

const T = styled.p`
  color: #e5e5e5;
  font-weight: 500;
  font-size: 1.2rem;
  margin: 2rem 0.2rem;
  @media only screen and (max-width: 768px) {
    font-weight: 510;
    font-size: 1rem;
  }
`;

const Ts = styled.p`
  color: #e5e5e5;
  font-weight: 500;
  font-size: 1rem;
  margin: 2rem 0.2rem;
  @media only screen and (max-width: 768px) {
    font-weight: 510;
    font-size: 1rem;
  }
`;

const Tu = styled(Trr)`
  text-align: center;
  margin-bottom: 1rem;
`;

const Tm = styled.p`
  color: #e5e5e5;
  font-weight: 500;
  font-size: 1.2rem;
  margin: 5rem 1rem 2rem 1rem;
  max-width: 1600px;
  @media only screen and (max-width: 768px) {
    font-weight: 510;
    font-size: 1rem;
  }
  @media only screen and (max-width: 600px) {
    margin: 3rem 1rem 2rem 1rem;
  }
`;

const Image = styled.div`
  width: 20rem;
  position: relative;
  display: flex;

  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 768px) {
    width: 80%;
  }

  @media only screen and (max-width: 600px) {
    width: 85%;
  }
`;
const SmBox = styled.div`
  margin: 0 2rem 0 0;
  width: 10rem;
  border-radius: 1.5rem;
  background-color: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(5px);
  @media only screen and (max-width: 768px) {
    margin: 2rem 0 0 0;
  }
`;

const Upper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;

  padding: 0 0.5rem;
  height: 4rem;
  @media only screen and (max-width: 768px) {
  }
`;

const Button = styled(motion.button)`
  background-color: red;
  color: #e5e5e5;
  border-radius: 1.5rem;
  cursor: pointer;
  padding: 0.8rem 2rem;
  border: none;
  font-size: 1.5rem;
  max-width: 14rem;
  margin: 2rem 0 0 0;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
`;
const Flex = styled.div`
  display: flex;
  align-items: center;
  //   margin: 2rem 0.2rem;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

const Page4 = () => {
  const [priceForNft, setpriceForNft] = useState(0);
  const [value, setvalue] = useState(0);
  const [isSaleActive, setIsSaleActive] = useState(true);
  const [maxSupplyExceeds, setMaxSupplyExceeds] = useState(false);

  const [noOfNFT, setNoOfNFT] = useState(0);

  const [maxNFTMint, setMaxNFTMint] = useState(5);

  // const wallet = useWallet()
  const darkThemeEnabled = useSelector((state) => state.darkThemeEnabled);
  const dispatch = useDispatch();
  const [loading, setloading] = useState(false);
  const [showButton, setShowButton] = useState(false);
  // const contract = useSelector((state) => state.contract);
  const [noOfToken, setnoOfToken] = useState(0);
  const [price1, setprice1] = useState("");
  const [totalCost, setTotalCost] = useState(0);
  const [isBalanceExceeds, setIsBalanceExceeds] = useState(false);

  const [account, setAccount] = useState();
  const [web3, setWeb3] = useState();
  const [provider, setProvider] = useState();
  const [price, setprice] = useState(0);

  const [contract, setContract] = useState();

  useEffect(() => {
    loadWeb3(provider);
  }, [provider]);

  useEffect(() => {
    if (web3) {
      loadContract();
    }
  }, [web3]);

  useEffect(() => {
    if (contract) {
      loadData();
    }
  }, [contract]);

  const loadData = async () => {
    console.log("accco", contract);
    let price = await contract.methods.kangarooHeroesPrice().call();
    // return price;
    console.log("price", price);
    let _isSaleActive = await contract.methods.saleIsActive().call();
    setIsSaleActive(_isSaleActive);
    let _maxMint = await contract.methods.maxKangarooHeroesPerWallet().call();
    setMaxNFTMint(_maxMint);



    let totalSupply = await contract.methods.totalSupply();
    setprice1(price / 10 ** 18);
    if(totalSupply === 3600){
      setMaxSupplyExceeds(true)
    }
  };

  const loadContract = async () => {
    let _contract = new web3.eth.Contract(ABI, config.CONTRACT);
    setContract(_contract);
  };

  const loadWeb3 = async (_provider) => {
    console.log("loadWeb3");
    let _web3;

    if (_provider) {
      _web3 = new Web3(_provider);

      const accounts = await _web3.eth.getAccounts();

      const address = accounts[0];
      if (address) {
        setAccount(address);
      }
      console.log("_web3", _web3, address);
    } else {
      var web3Provider = new Web3.providers.HttpProvider(config.RPC);
      _web3 = new Web3(web3Provider);
      setAccount(null);
    }
    setWeb3(_web3);
  };



  function sendTransaction(_tx) {
    return new Promise((resolve, reject) => {
      web3.eth
        .sendTransaction(_tx)
        .once("transactionHash", (txHash) => resolve(txHash))
        .catch((err) => reject(err));
    });
  }

  useEffect(() => {
    setTotalCost(noOfNFT * price1);
  }, [noOfNFT]);

  function sanitizeHex(hex) {
    hex = hex.substring(0, 2) === "0x" ? hex.substring(2) : hex;
    if (hex === "") {
      return "";
    }


    hex = hex.length % 2 !== 0 ? "0" + hex : hex;
    return "0x" + hex;
  }


   function convertStringToHex(value) {
    return new BigNumber(`${value}`).toString(16)
  }


  const handleMint = async () => {
    setloading(true);
    if (parseInt(noOfNFT) == 0) {
      alert("No. of token can not be zero");
      setloading(false);
    } else {
      try {
        var web3Provider = new Web3.providers.HttpProvider(config.RPC);

        const _webRead3 = new Web3(web3Provider);
        const wContract = new _webRead3.eth.Contract(ABI, config.CONTRACT);

        const txData = await wContract.methods.mint(noOfNFT).encodeABI();
        // const _nonce = await apiGetAccountNonce(account, 1);
        // const nonce = sanitizeHex(convertStringToHex(_nonce));
        
        const gasPrices = await apiGetGasPrices();
        const _gasPrice = gasPrices.normal.gwei;
        const gasPrice = sanitizeHex(
          convertStringToHex(convertAmountToRawNumber(_gasPrice, 9))
        );
      

      

      // console.log("ssasasaassa",gasLimit)

        // gasLimit
      //   // const _gasLimit = 21000;
      //   // const gasLimit = sanitizeHex(convertStringToHex(_gasLimit));
      //     console.log("account",account)
      //   const gasLimit = await wContract.methods.mint(noOfNFT).estimateGas(
      //     {
      //         from: account,
      //         gasPrice:1000*_gasPrice
      //     } 
      // )


      // console.log("gaLim",gasLimit)
      
      const gasLimit = await wContract.methods.mint(noOfNFT).estimateGas({
        from: account,
        value: Web3.utils.toWei((noOfNFT * price1).toString()),
      });


 
        let tx =   await sendTransaction( {
          from: account,
          to: config.CONTRACT,
          // nonce,
          chainId:1,
          gasPrice,
          gasLimit:gasLimit,
          value: Web3.utils.toWei((noOfNFT * price1).toString()),
          data: txData,
        })

      


        // console.log("gassssss",gas)
        if (tx.status) {
          setloading(false);
          alert("Transaction Successfull");
        } else {
          setloading(false);
        }

        // alert(tx);
      } catch (Err) {
        setloading(false);
        console.log("errr",Err)
      }
    }
  };

  const showButtonTop = () => {
    // console.log(window.scrollY)
    if (window.scrollY >= 600) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  };

  useEffect(() => {
    showButtonTop();
    // adding the event when scroll change background
    window.addEventListener("scroll", showButtonTop);
  });

  const connectWallet = async () => {
    const _provider = await web3Modal.connect();
    await _provider.enable();
    await web3Modal.toggleModal();



    _provider.on("accountsChanged", (accounts) => {
      console.log(accounts);
      setAccount(accounts[accounts.length-1])
    });
    setProvider(_provider);
  };
  // useEffect(() => {
  //   // getPrice();

  // }, [darkThemeEnabled, contract]);

  const loadMyBal = async () => {
    let myBal = await contract.methods.balanceOf(account).call();
    if (myBal >= maxNFTMint) {
      setIsBalanceExceeds(true);
    }
  };

  useEffect(() => {
    if (account && contract) {
      loadMyBal();
    }
  }, [account, contract]);

  const handleInput = (e) => {
    if (e.target.value != "") {
      setnoOfToken(e.target.value);
    } else {
      setnoOfToken(e.target.value);
    }
  };

  const calculatePriceToken = async (token) => {
    if (token !== 0) {
      const priceForToken = await contract.methods.calculatePrice(token).call();
      setprice(priceForToken);
      console.log(priceForToken);
    } else {
      alert("Token amount cant be zero");
    }
  };

  const renderButton = () => {


    return  <Button
    whileHover={{ scale: 1.1, boxShadow: "0 0 25px 	#000" }}
    whileTap={{ scale: 0.7 }}
    type="button"
    onClick={() => {
      // handleMint();
      window.location = "https://opensea.io/collection/kangarooheroesoriginal"
    }}

  >

    Buy From OpenSea
    {/* {totalCost ? `Pay ${totalCost.toFixed(4)} ETH` : " MINT NOW"} */}
  </Button>
    if (account) {
      if (loading) {
        return <Button type="button">Loading ...</Button>;
           } 
      
        else if (maxSupplyExceeds) {
       
       
          return (
            <div style={{ textAlign: "center" }}>
              <Button
                whileHover={{ scale: 1.1, boxShadow: "0 0 25px 	#000" }}
                whileTap={{ scale: 0.7 }}
                type="button"
                disabled
              >
                Max Supply Exceeds
              </Button>
            </div>
          );
        }
      else if (!isSaleActive) {
       
       
        return (
          <div style={{ textAlign: "center" }}>
            <Button
              whileHover={{ scale: 1.1, boxShadow: "0 0 25px 	#000" }}
              whileTap={{ scale: 0.7 }}
              type="button"
              disabled
            >
              Sale not Active
            </Button>
          </div>
        );
      } else if (isBalanceExceeds) {
        return (
          <div style={{ textAlign: "center" }}>
            <Button
              whileHover={{ scale: 1.1, boxShadow: "0 0 25px 	#000" }}
              whileTap={{ scale: 0.7 }}
              type="button"
              disabled
            >
              You can't mint more than {maxNFTMint}
            </Button>
          </div>
        );
      } else {
        return (
          <div style={{ textAlign: "center" }}>
            {/* <div>
              <div style={{ display: "flex" }}>
                <input type="number" value={noOfToken} style={{ padding: "20px", borderRadius: "20px", fontSize: "22px", marginBottom: "20px" }} onChange={(e) => {
                  handleInput(e)
                }} />
              </div>
            </div> */}
            {/* {
              price !== 0 && <div style={{ marginBottom: "10px" }}>
                You have to pay {price / 10 ** 18}
              </div>
            } */}
            <Button
              whileHover={{ scale: 1.1, boxShadow: "0 0 25px 	#000" }}
              whileTap={{ scale: 0.7 }}
              type="button"
              onClick={() => {
                // handleMint();
                window.location = "https://opensea.io/collection/kangarooheroesoriginal"
              }}

            >

              Buy From OpenSea
              {/* {totalCost ? `Pay ${totalCost.toFixed(4)} ETH` : " MINT NOW"} */}
            </Button>
          </div>
        );
      }
    } else {
      return (
        <Button
          onClick={() => {
            connectWallet();
          }}
          type="button"
          whileHover={{ scale: 1.1, boxShadow: "0 0 15px rgb(250, 0, 117)" }}
          whileTap={{ scale: 0.7 }}
        >
          CONNECT
        </Button>
      );
    }
  };

  return (
    <Sec id="learn">
      <Width>
        <Left
          initial={{ x: 0, y: 0 }}
          animate={{ x: 25, y: 0 }}
          transition={{ duration: 1, repeat: Infinity, repeatType: "reverse" }}
        >
          {/* <Trr>LIMITED SUPPLY</Trr> */}

          <img src={i} alt="" style={{ width: "100%", height: "100%" }} />
        </Left>
        <Right data-aos="fade-left">
          <Image>
            <Trr>Sold Out</Trr>

            <img src={i} alt="" style={{ width: "100%", height: "100%" }} />
          </Image>

          {/* {account ? (
            <h2 style={{ color: "#f5ff38" }}>Your Account :{account}</h2>
          ) : null} */}
          {/* <T>
            Buying a Kangaroo Hero costs 0.07 ETH. Once sold out on our website,
            you can purchase a Kangaroo Hero from Opensea.
          </T> */}
          {/* <Flex>
            <SmBox>
              <Upper>
                <FaMinusCircle
                  className="iconL"
                  onClick={() => {
                    if (noOfNFT > 0) {
                      setNoOfNFT(noOfNFT - 1);
                    }
                  }}
                />

                <input
                  placeholder="0"
                  value={noOfNFT}
                  style={{
                    width: "70%",
                    height: "60%",
                    border: "none",
                    fontSize: "1.5rem",
                    textAlign: "center",
                    backgroundColor: "transparent",
                    color: "#E5E5E5",
                  }}
                  onChange={(e) => {
                    setpriceForNft(parseInt(e.target.value) * price1 || 0);
                    setNoOfNFT(parseInt(e.target.value));
                    setvalue(e.target.value);
                  }}
                  type="number"
                />

                <FcPlus
                  className="iconR"
                  onClick={() => {
                    if (noOfNFT < maxNFTMint) {
                      setNoOfNFT(noOfNFT + 1);
                    }
                  }}
                />
              </Upper>
            </SmBox>
            <Ts>Enter the amount of Kangaroo you would like to buy(5 max)</Ts>
          </Flex> */}
          {renderButton()}
          {/* <Button
          type="button"
          whileHover={{ scale: 1.1, boxShadow: "0 0 15px rgb(250, 0, 117)" }}
          whileTap={{ scale: 0.7 }}
        >
          Mint Now
        </Button> */}
        </Right>
      </Width>
      <Tm>
        Note: 500 Kangaroos are being withheld from the sale. These will be used
        as rewards for our amazing staff who will fuel our community, giveaways
        to drive traffic toward the project and as random acts of love for our
        amazing Kangaroo Hero Members in the future.
      </Tm>
    </Sec>
  );
};

export default Page4;
