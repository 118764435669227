import React, { useEffect, useState } from 'react'
import Web3 from 'web3';
import abi from './abi/abis.json';
import { accountUpdate, contractLoaded, ethtodollar } from '../redux/actions';
import { connect, useSelector } from 'react-redux';
import { useWallet } from 'use-wallet';
import config from './config';




function BlockchainProvider(props) {

    const {account,ethereum} = useWallet()
    useEffect(() => {
        console.log(props.eth)
        //const contractAddr = Config.CONTRACT_ADDRESS
        var web3Provider = new Web3.providers.HttpProvider(config.RPC);

        const web3 = new Web3(web3Provider);
        try {
            // account(web3)
            const SimpleContract = new web3.eth.Contract(abi, config.CONTRACT);
            props.dispatch(contractLoaded(SimpleContract))
            console.log(SimpleContract, "contract")
            props.dispatch(ethtodollar(props.eth))
        }
        catch (err) {
            console.log(err)
        }
        return () => { }
    }, [])

   useEffect(()=>{
    if(account){
        props.dispatch(accountUpdate(account))

    }
   },[account])




   useEffect(()=>{
    // const provider = await web3Modal.connect();
    // setProvider(provider);

   },[])



    return (
        <div>

        </div>
    )
}
const mapStateToProps = function (state) {
    return {
    }
}

export default connect(mapStateToProps)(BlockchainProvider)
