import React,{ useState, useEffect} from 'react'
import styled from 'styled-components'
import './Dark.css'
// import bg from './bg.gif'
import blue from './whaLe.png'
import yellow from './yellow.png'
import { motion } from 'framer-motion'
import DarkModeToggle from "react-dark-mode-toggle";
import { useSelector, useDispatch } from "react-redux";
import { TOGGLE_DARKTHEME } from "../../redux/actions";
import theme from "styled-theming";
import { BsBoxArrowUp } from 'react-icons/bs';
import { Link, animateScroll as scroll } from "react-scroll";

import backg from './bg.png'
import left from './left.png'
import right from './right.png'
import Aos from 'aos'
import 'aos/dist/aos.css'

const buttonColor = theme("theme", {
    light: "linear-gradient(to right, #000000, #434343)",
    dark: "linear-gradient(to right, #ff4e50, #f9d423)",
  });

const bColor = theme("theme", {
    light: "#E5E5E5",
    dark: "#000000",
  });  

const iconColor = theme("theme", {
    light: "linear-gradient(to right, #0f0c29, #302b63, #24243e)",
    dark: "linear-gradient(to right, #fc00ff, #00dbde)",
  });  

  const heroImg = theme("theme", {
    light: blue,
    dark: blue,
  });  

 
const HomePage = styled.section`
  display: flex;
  overflow-x: hidden !important;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  width: 100%;
  background-image: url(${backg});
  background-size: 100% 100%;
  background-repeat: no-repeat;
@media only screen and (max-width: 768px) {
    min-height: 50rem;
    width: 100%;
    padding: 0rem 0.5rem 2rem 0.5rem;
    background-size: auto 100%;
    flex-direction: column;
}`;

const Width  = styled.div`
    width: 1600px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media only screen and (max-width: 1700px) {
        width: 93%;
        justify-content: space-between;
    }
    @media only screen and (max-width: 1400px) {
        width: 96%;
        justify-content: space-between;
    }
    @media only screen and (max-width: 1000px) {
        width: 99%;
       
    }
    @media only screen and (max-width: 800px) {
        width: 99%;
        flex-direction: column;
        margin: 10rem  0 0 0;
    }
    @media only screen and (max-width: 600px) {
        width: 99%;
        
        margin: 5rem  0 0 0;
    }
`

export const T = styled.p`
@font-face {
  font-family: comicon;
  src: url('./comi.ttf');
}
   position: absolute;
    font-family: comicon !important;
    color: #de032e    ;
    font-size: 5.7rem;
    margin: 1rem  0 0 0;
    font-weight: bold;
    @media only screen and (max-width: 1300px) {
      font-weight: 510;
      font-size: 4.5rem;
    }
    @media only screen and (max-width: 998px) {
      font-weight: 510;
      font-size: 3.7rem;
    }
    @media only screen and (max-width: 768px) {
        font-weight: 510;
        font-size: 3rem;
      }
      @media only screen and (max-width: 500px) {
        font-weight: 510;
        font-size: 2.2rem;
      }
`;

const ShowButton = styled(Link)`
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
    background:linear-gradient(to right, #fc00ff, #00dbde);
    color: #FFF ;
    font-weight: bold;
    cursor: pointer;
    position: fixed;
    height: 4rem;
    width: 3rem;
    border-radius: 0.5rem;
    transition:  0.5s linear;
    right: 2rem ;
    bottom: 5rem;
    display: grid;
    place-items: center;
    z-index: 9999;
    
`
const Left = styled(motion.div)`
    
    width: 56rem;
    position: relative;
    display: flex;
    
    align-items: center;
    justify-content: center;
   
    @media only screen and (max-width: 1200px) {
      width: 44rem;
    }
    @media only screen and (max-width: 1000px) {
        width: 85%;
    }
    @media only screen and (max-width: 998px) {
        height: auto;
        width: 100%;
        align-items: center;
        justify-content: center;
    }
    
    @media only screen and (max-width: 600px) {
        height: auto;
        width: 100%;
        align-items: center;
    }
`;

const Right = styled(motion.div)`
    width: 30rem;
    @media only screen and (max-width: 1600px) {
        
        width: 30rem;
       
    }
    @media only screen and (max-width: 1400px) {
        
        width: 26rem;
       
    }
    @media only screen and (max-width: 1200px) {
        
        width: 20rem;
       
    }
    @media only screen and (max-width: 800px) {
        
       
        margin: 3rem  0 0 0;
    }
    @media only screen and (max-width: 600px) {
        height: auto;
        width: 70%;
       
    }
`;

const Home = () => {

    const darkThemeEnabled = useSelector((state) => state.darkThemeEnabled);
    const dispatch = useDispatch();
    
    const [ showButton, setShowButton ] = useState(false)

    const showButtonTop = () => {
      // console.log(window.scrollY)
      if (window.scrollY >= 600) {
        setShowButton(true)
      } else {
        setShowButton(false)
      }
    }
  
    useEffect(() => {
      showButtonTop()
      // adding the event when scroll change background
      window.addEventListener("scroll", showButtonTop)
    })  

    useEffect(() => {
      
    },[darkThemeEnabled])

    useEffect(()=>{
      Aos.init({duration: 2000}) // global duration
    },[]) 


    return (
        <HomePage id="home" className='h'>

            <Width>
              <Left
                data-aos="fade-right"
              >
                <T>KANGAROO <br/>HEROES</T>
                <img src={left} alt="" style={{width:"100%",height:"100%"}} />
              </Left>
              <Right
                initial={{ x: 0, y:0}}
                animate={{ x: 0, y: -15 }}
                transition={{ duration: 1, repeat: Infinity ,repeatType: "reverse"}}
                
              >
                <img src={right} alt="" style={{width:"100%",height:"100%"}} />
              </Right>
            </Width>

            {/* <ModeButton
                onChange={() => dispatch({ type: TOGGLE_DARKTHEME })}
                checked={darkThemeEnabled}
                size={80}
            /> */}

          { showButton ? 
          
            <ShowButton
              type="button"
              to="home" spy={true} smooth={true}
             
            >
              <BsBoxArrowUp size="2rem"/>
            </ShowButton> :
            
            null
          }
            
            
        </HomePage>
    )
}

export default Home
