import React,{ useState, useEffect} from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { SiHomeassistant } from 'react-icons/si';
import { IoIosPodium } from 'react-icons/io';
import { SiAzuredataexplorer } from 'react-icons/si';
import { SiZig } from 'react-icons/si'
import backg from './rd.png'

const Section = styled.section`
  display: flex;
  overflow-x: hidden !important;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  width: 100%;
  background-image: url(${backg});
  background-size: 100% 100%;
  background-repeat: no-repeat;
@media only screen and (max-width: 768px) {
    min-height: 50rem;
    width: 100%;
    padding: 0rem 0.5rem 2rem 0.5rem;
    background-size: auto 100%;
    flex-direction: column;
}`;

const Width  = styled.div`
    width: 1600px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media only screen and (max-width: 1700px) {
        width: 93%;
        justify-content: space-between;
    }
    @media only screen and (max-width: 1400px) {
        width: 96%;
        justify-content: space-between;
    }
    @media only screen and (max-width: 1000px) {
        width: 99%;
       
    }
    @media only screen and (max-width: 800px) {
        width: 99%;
        flex-direction: column;
        margin: 10rem  0 0 0;
    }
    @media only screen and (max-width: 600px) {
        width: 99%;
        
        margin: 5rem  0 0 0;
    }
`

const T = styled.p`
    color: #000000 ;
    font-weight: 500;
    font-size: 1.40rem;
    margin-bottom: 2rem;
    @media only screen and (max-width: 768px) {
        font-weight: 510;
        font-size: 1rem;
      }
`;

const Mobile = styled.div`
    display: none;
    @media only screen and (max-width: 768px) {
        display:block;
        width: 100%;
        height: auto;
      }
`;

const Center = styled.div`
        width: auto;
        height: auto;
        display: grid;
        place-items: center;
        padding: 1rem 8rem 0 8rem;
        background-color: rgba(135, 206, 235, .4);  
 backdrop-filter: blur(5px);
      border-radius: 0.5rem;
      @media only screen and (max-width: 800px) {
        padding: 1rem 4rem 0 4rem;
      }
      @media only screen and (max-width: 500px) {
        padding: 1rem 1rem 0 1rem;
        width: 99%;
      }
`;


const Roadmap = () => {

    return (
        <Section id="rarity">

            <Width>
            <VerticalTimeline>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={{ background: '#E5E5E5', color: '#000' }}
                    contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                    date="Phase 01"
                    iconStyle={{ background: '#000', color: '#fff' }}
                    icon={<SiHomeassistant />}
                >
                    {/* <h3 className="vertical-timeline-element-title">0%</h3> */}
                    <h4 style={{fontSize:"1.4rem"}} className="vertical-timeline-element-subtitle">10%</h4>
                    <T>
                    We pay our team who spent hours helping us to educate our community launch this amazing project
                    </T>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={{ background: '#E5E5E5', color: '#000' }}
                    date="Phase 02"
                    iconStyle={{ background: 'rgba(237, 73, 74, 1)', color: '#fff' }}
                    icon={<IoIosPodium />}
                >
                    <h3 className="vertical-timeline-element-title"></h3>
                    <h4 style={{fontSize:"1.4rem"}} className="vertical-timeline-element-subtitle">20%</h4>
                    <T>
                    We giveaway 5 Kangaroo Heroes to random holders

                    </T>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={{ background: '#E5E5E5', color: '#000' }}
                    date="Phase 03"
                    iconStyle={{ background: 'rgba(237, 73, 74, 1)', color: '#fff' }}
                    icon={<SiAzuredataexplorer />}
                >
                    <h3 className="vertical-timeline-element-title"></h3>
                    <h4 style={{fontSize:"1.4rem"}} className="vertical-timeline-element-subtitle">40%</h4>
                    <T>
                    We launch our membership portal for all current holders to get access to a private Discord Group and Discount Codes. 

                    </T>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={{ background: '#E5E5E5', color: '#000' }}
                    date="Phase 04"
                    iconStyle={{ background: 'rgba(237, 73, 74, 1)', color: '#fff' }}
                    icon={<SiZig />}
                >
                    <h3 className="vertical-timeline-element-title"></h3>
                    <h4 style={{fontSize:"1.4rem"}} className="vertical-timeline-element-subtitle">60%</h4>
                    <T>
                    We host our first of many virtual financial education / skill set events that will happen quarterly with special guests
                    </T>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={{ background: '#E5E5E5', color: '#000' }}
                    date="Phase 05"
                    iconStyle={{ background: 'rgba(237, 73, 74, 1)', color: '#fff' }}
                    icon={<SiZig />}
                >
                    <h3 className="vertical-timeline-element-title"></h3>
                    <h4 style={{fontSize:"1.4rem"}} className="vertical-timeline-element-subtitle">70%</h4>
                    <T>
                    We set the date for our first in-person financial education / networking conference for holders to meet.
                    </T>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={{ background: '#E5E5E5', color: '#000' }}
                    date="Phase 06                   "
                    iconStyle={{ background: '#000', color: '#fff' }}
                    icon={<SiZig />}
                >
                    <h3 className="vertical-timeline-element-title"></h3>
                    <h4 style={{fontSize:"1.4rem"}} className="vertical-timeline-element-subtitle">80%</h4>
                    <T>
                    We expand upon our partnerships by creating a program to bring in more quality financial & small business service providers

                    </T>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={{ background: '#E5E5E5', color: '#000' }}
                    date="Phase 07                  "
                    iconStyle={{ background: '#000', color: '#fff' }}
                    icon={<SiZig />}
                >
                    <h3 className="vertical-timeline-element-title"></h3>
                    <h4 style={{fontSize:"1.4rem"}} className="vertical-timeline-element-subtitle">100%</h4>
                    <T>
                    We continue to think of new ways to bring value and utility to Kangaroo Hero holders outside of the utility that’s provided.

                    </T>
                </VerticalTimelineElement>
              </VerticalTimeline>
            </Width>
            
        </Section>
    )
}

export default Roadmap
