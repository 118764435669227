import React from 'react'
import styled from 'styled-components'
import img from '../../components/Navbar/logo.png'
import Tilt from 'react-parallax-tilt';
import bg from './2.png'
import theme from "styled-theming";

import e from './E.png'
import { Trr } from '../Page2/Page2';

export const backgroundColor = theme("theme", {
  light: "#000000",
  dark: "#E5E5E5",
});

const Sec = styled.section`
    min-height: 100vh;
    display: flex;
    overflow-x: hidden !important;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 3rem 0;
    background-image: radial-gradient(circle, #000000, #0d0d0d, #161617, #1d1d1e, #232526);
    @media only screen and (max-width: 1400px) {
      padding: 3rem 2rem;
  }
    @media only screen and (max-width: 768px) {
       
        padding: 1rem 0 2rem 0;
        flex-direction: column;
        justify-content: space-around;
      }
`;
export const Width = styled.div`
      width: 1600px;
      display: flex;
        
        align-items: center;
        justify-content: space-between;
      @media only screen and (max-width: 1700px) {
        width: 95%;
      }
    @media only screen and (max-width: 1400px) {
        width: 99%;
    }
    @media only screen and (max-width: 1100px) {
        flex-direction: column;
    }

`

const Left = styled(Tilt)`
        margin-right: 2rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 40%;
        position: relative;
        @media only screen and (max-width: 1100px) {
          
          width: 30rem;
      }
    @media only screen and (max-width: 768px) {
        margin-right: 0;
        width: 100%;
        padding: 0 1rem;
        height: auto;
      }
`;

const Right = styled.div`
      margin-left: 2rem;
      display: flex;
      width: 50%;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
    @media only screen and (max-width: 1100px) {
        margin: 4rem 0 0 0;
      width: 95%;
      justify-content: center;
  }
      @media only screen and (max-width: 768px) {
        width: 100%;
        height: auto;
        margin-left: 0;
        padding: 0 1rem;
        flex-direction: column;
      }
`;

const T = styled.p`
    color: #E5E5E5;
    font-weight: 500;
    font-size: 1.3rem;
    margin: 2rem 0 0 0;
    text-align: left;
    @media only screen and (max-width: 768px) {
        font-weight: 510;
        font-size: 1rem;
       
      }
`;

const Tru = styled(Trr)`
      font-size: 6rem;
      margin: 0;
      line-height: 1;
      text-align: center;
      @media only screen and (max-width: 768px) {
        font-size: 4rem;
      }
`
const Trub = styled.p`
      font-size: 2rem;
      @media only screen and (max-width: 768px) {
        font-size: 1.6rem;
      }
`

const Image1 = styled.div`
      width: 30rem;
      position: relative;
    display: flex;
    
    align-items: center;
    justify-content: center;
      @media only screen and (max-width: 1400px) {
        width: 22rem;
        
    }
    @media only screen and (max-width: 600px) {
      width: 95%;
      height: auto;
  }
`

const Page2 = () => {

  return (
    <Sec >

      <Width>

        <Left>

          <img src={bg} alt="" style={{ width: "100%", height: "100%" }} />

        </Left>

        <Right>

          <Image1>
            <Tru>
              NFT 
              <br />
              <Trub>SUPERPOWERS</Trub>
            </Tru>
            <img src={e} alt="" style={{ width: "100%", height: "100%" }} />
          </Image1>

          <T>
            Our goal is to create an NFT Project that not only offers virtual perks but actual REAL WORLD utility. So we launched our own virtual accounting franchise that offers personal & small business tax preparation, bookkeeping, tax planning and life insurance. As a holder you will be able to receive 30% OFF on these services at any time for as long as you are a holder. And these are services you actually NEED. The savings will exceed the cost of buying an NFT.
          </T>

        </Right>


      </Width>
    </Sec>
  )
}

export default Page2
