import React from 'react'
import './Back.css'
import styled from 'styled-components'
// import bg from './bbg.png'
// import nbg from './nbg.png'
import theme from "styled-theming";
import bbg from './bbg.png'
import nbg from './nbg.png'
import { T } from '../Page5/Page5';
import e from './E.png'
import t from './talk.png'
import { Trr } from '../Page2/Page2';

const backgroundImagePage6 = theme("theme", {
  light: bbg,
  dark: nbg,
});

const backgroundColor = theme("theme", {
  light: "#a1dcf2",
  dark: "#27343a",
});


export const bColor = theme("theme", {
  light: "#000000",
  dark: "#E5E5E5",
});


const Sec = styled.section`
    display: flex;
    align-items: center;
    overflow-x: hidden !important;
    justify-content: center;
    background-image: radial-gradient(circle, #000000, #0d0d0d, #161617, #1d1d1e, #232526);
    min-height: 100vh;
    width: 100%;
    padding: 3rem 0.5rem 3rem 0.5rem;
    @media only screen and (max-width: 768px) {
        width: 100%;
        padding: 5rem 0.5rem 5rem 0.5rem;
      
    }

`

const Left = styled.div`
        width: 40vw;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    @media only screen and (max-width: 768px) {
        margin-right: 0;
        width: 100%;
        padding: 0 1rem;
      }
`;

const Right = styled.div`
      width: 40vw;
      display: flex;
        flex-direction: column;
        justify-content: space-between;
      @media only screen and (max-width: 768px) {
        width: 100%;
        padding: 0 1rem;
      }
`;

const Width = styled.div`
      width: 1600px;
      display: flex;
      flex-direction: column;
        align-items: center;
        justify-content: space-between;
      @media only screen and (max-width: 1700px) {
        width: 95%;
      }
    @media only screen and (max-width: 1400px) {
        width: 99%;
    }
    @media only screen and (max-width: 1100px) {
        
    }

`
const Image = styled.div`
      width: 35rem;
      position: relative;
    display: flex;
    
    align-items: center;
    justify-content: center;
      @media only screen and (max-width: 1100px) {
        width: 30rem;
    }
    @media only screen and (max-width: 768px) {
      width: 98%;
  }
`
const Tu = styled(T)`

`
const Tru = styled(Trr)`
    font-size: 2.8rem;
    text-align: center;
    @media only screen and (max-width: 450px) {
      font-size: 1.6rem;
    }
`

const Trub = styled.p`
    font-size: 2rem;
    text-align: center;
    @media only screen and (max-width: 450px) {
      font-size: 1.4rem;
    }
`
const Trs = styled(Trr)`
    font-size: 2.4rem;
    
    
`

const Grid = styled.div`
     display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
  place-items: center;
  width 100%;
`
const SmallImg = styled.div`
      width: 15.5rem;
      position: relative;
      display: flex;
      
      align-items: center;
      justify-content: center;
      @media only screen and (max-width: 1700px) {
        width: 13.5rem;
      }
      @media only screen and (max-width: 600px) {
        width: 90%;
      }
`
const Box = styled.div`
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 2rem 0;
      height: 30rem;
      padding: 0 1rem;
      @media only screen and (max-width: 669px) {
        width: 95%;
        height: auto;
      }
`

const Page6 = () => {
  return (
    <Sec id="roadmap">
      <Width>
        <Image>
          <Tru>kangaroo HEROES
            <br />
            <Trub> MEMBER BENEFITS</Trub>
          </Tru>

          <img src={e} alt="" style={{ width: "100%", height: "100%" }} />
        </Image>
        <Tu>
          Buying a Kangaroo Hero is more than owning a piece of artwork. This is a project that provides you knowledge, value and real life services in the long-term. Use your Kangaroo as a social media identity or gain access to real world benefits from our Conference, discounts on financial services and rare superpower traits with higher access.

          {/* Buying a Kangaroo Hero is more than owning a piece of artwork. This is a membership that provides you knowledge, value and services in the long-term. Use your Kangaroo as a social media identity or gain access to real world benefits from our partnered financial services company. */}
        </Tu>
        <Grid>
          <Box>
            <SmallImg>
              <Trs
                style={{ marginBottom: "2rem" }}
              >
                UNIQUE
              </Trs>
              <img src={t} alt="" style={{ width: "100%", height: "100%" }} />
            </SmallImg>
            <T>
              <span style={{ fontWeight: "bold" }}>4,444</span> Unique Kangaroo Heroes
            </T>
          </Box>
          <Box>
            <SmallImg>
              <Trs
                style={{ marginBottom: "2rem" }}
              >
                COST
              </Trs>
              <img src={t} alt="" style={{ width: "100%", height: "100%" }} />
            </SmallImg>
            <T>
              Fair Launch. All Kangaroos cost <span style={{ fontWeight: "bold" }}>0.07 ETH</span>
            </T>
          </Box>
          <Box>
            <SmallImg>
              <Trs
                style={{ marginBottom: "2rem" }}
              >
                EVENTS
              </Trs>
              <img src={t} alt="" style={{ width: "100%", height: "100%" }} />
            </SmallImg>
            <T>
              Exclusive ticket to Kangaroo Con. The largest NFT Financial Event in the country. Tickets will not be sold to the public.
              {/* Membership Portal to access quarterly <span style={{ fontWeight: "bold" }}>financial literacy & skill-based events</span> */}
            </T>
          </Box>
          <Box>
            <SmallImg>
              <Trs
                style={{ marginBottom: "2rem" }}
              >
                DISCOUNTS
              </Trs>
              <img src={t} alt="" style={{ width: "100%", height: "100%" }} />
            </SmallImg>

            <T>
              <span style={{ fontWeight: "bold" }}>30% Off Member discounts</span>  on financial services from our from our virtual accounting franchise
            </T>
          </Box>
        </Grid>
      </Width>
    </Sec>
  )
}

export default Page6
