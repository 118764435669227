import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './redux/store';
import { useWallet, UseWalletProvider } from 'use-wallet'


ReactDOM.render(<Provider store={store}>
    <UseWalletProvider
        chainId={1}
    connectors={{
        // This is how connectors get configured
        portis: { dAppId: 'my-dapp-id-123-xyz' },
        fortmatic:{apiKey:"pk_live_3FAE143BAD09A1E8"},

        walletconnect:{
            rpc:{
                1:"https://20NW4m1fVikhFodQ6XA3prwU5cL:b745ea30658c1ca69d74ef21e5b8c8c4@eth2-beacon-mainnet.infura.io"
            }
        }
    }}
    >
        <App />
    </UseWalletProvider>
</Provider>, document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
