import React from 'react'
import styled from 'styled-components'
import img from '../../components/Navbar/logo.png'
import Tilt from 'react-parallax-tilt';
import bg from './sp.png'
import theme from "styled-theming";
import e from './E.png'
import le from './1.png'
import { Trr } from '../Page2/Page2';

export const backgroundColor = theme("theme", {
  light: "#000000",
  dark: "#E5E5E5",
});

const Sec = styled.section`
    min-height: 100vh;
    display: flex;
    overflow-x: hidden !important;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 3rem 0;
    background-image: radial-gradient(circle, #000000, #0d0d0d, #161617, #1d1d1e, #232526);
    @media only screen and (max-width: 1400px) {
      padding: 3rem 2rem;
  }
    @media only screen and (max-width: 768px) {
       
        padding: 1rem 0 2rem 0;
        flex-direction: column;
        justify-content: space-around;
      }
`;
export const Width = styled.div`
      width: 1600px;
      display: flex;
        
        align-items: center;
        justify-content: space-between;
      @media only screen and (max-width: 1700px) {
        width: 95%;
      }
    @media only screen and (max-width: 1400px) {
        width: 99%;
    }
    @media only screen and (max-width: 1100px) {
        flex-direction: column;
    }

`

const Left = styled.div`
        margin-right: 2rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 55%;
        // background-image: url(${bg});
        // background-size: 100% 100%;
        // background-repeat: no-repeat;
        @media only screen and (max-width: 1100px) {
          
          width: 30rem;
      }
    @media only screen and (max-width: 768px) {
        margin-right: 0;
        width: 100%;
        padding: 0 1rem;
        height: auto;
      }
`;

const Right = styled(Tilt)`
      margin: 6rem 0 0 2rem;
      display: flex;
      width: 40%;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
    @media only screen and (max-width: 1100px) {
        margin: 4rem 0 0 0;
      width: 95%;
      justify-content: center;
  }
      @media only screen and (max-width: 768px) {
        width: 100%;
        height: auto;
        margin-left: 0;
        padding: 0 1rem;
        flex-direction: column;
      }
`;

const T = styled.p`
    color: #E5E5E5;
    font-weight: 500;
    font-size: 1.2rem;
    margin: 2rem 0 0 0;
    text-align: left;
    @media only screen and (max-width: 768px) {
        font-weight: 510;
        font-size: 1rem;
       
      }
`;

const Image1 = styled.div`
      width: 30rem;
      position: relative;
    display: flex;
    
    align-items: center;
    justify-content: center;
      @media only screen and (max-width: 1400px) {
        width: 22rem;
        
    }
    @media only screen and (max-width: 600px) {
      width: 95%;
      height: auto;
  }
`
const Tru = styled(Trr)`
    font-size: 3rem;
`

const Page2 = () => {

  return (
    <Sec id="rarity">

      <Width>

        <Left>

          <Image1>
            <Tru>
              HERO'S <br /> ROADMAP
            </Tru>
            <img src={e} alt="" style={{ width: "100%", height: "100%" }} />
          </Image1>
          <T>
            Kangaroo Heroes is a collection of 4,444 Kangaroo NFT’s that are unique and live on the Ethereum Blockchain. The Kangaroo Hero is based off the mascot of our company tax & accounting franchise Jumping Jack Taxes. As the Jumping Jack Tax Brand grows across the country, it would create more exposure for our Kangaroo Heroes NFT project. Get access to Kangaroo Con, superpower traits with private access and discounts on financial services.
          </T>
          {/* <T>
            Kangaroo Heroes is a collection of 10,000 Kangaroo NFT’s that are unique and live on the Ethereum Blockchain. Your Kangaroo Hero grants you access to member-only benefits that include Quarterly Financial Literacy & Skill-Based Events (Virtual) from people who specialize in Real Estate, Accounting, Credit, Stock Market, Crypto and other skill based professions so you can use the knowledge to create financial independence for yourself and family.
          </T> */}
          <T>
            We also built our own virtual accounting firm so members can get lifetime discounts on financial services such as personal/small business tax preparation, bookkeeping and tax planning services through our partnered accounting firm.
          </T>
          <T>
            Members also get access to in-person financial festivals.
          </T>
          <T>
            The Kangaroo Heroes have a mission to help you achieve financial freedom.
          </T>

        </Left>

        <Right>

          <img src={le} alt="" style={{ width: "100%", height: "100%" }} />

        </Right>




      </Width>
    </Sec>
  )
}

export default Page2
