import axios, { AxiosInstance } from 'axios'

const api = axios.create({
  baseURL: 'https://ethereum-api.xyz',
  timeout: 30000, // 30 secs
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})



export const apiGetAccountNonce = async (
  address,chainId) => {
  const response = await api.get(
    `/account-nonce?address=${address}&chainId=${chainId}`
  )
  const { result } = response.data
  return result
}

export const apiGetGasPrices = async () => {
  const response = await axios.get(`https://ethgas.watch/api/gas`)
  return  response.data
}