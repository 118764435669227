import React from 'react'
import styled from 'styled-components';
import i1 from './1.JPG'
import i2 from './2.JPG'
import i3 from './3.JPG'
import i4 from './4.JPG'
import i5 from './5.JPG'

const Sec = styled.section`
    min-height: 100vh;
    width: 100%;
    display: grid;
    place-items: center;
    padding: 0 10% 4rem 10%;
    background-color: #000;
    @media only screen and (max-width: 768px) {
        min-height: 100vh;
        width: 100%;
        padding: 2rem 0;
        flex-direction: column;
        justify-content: space-around;
      }
`;

const Img = styled.div`
        width: 30rem;
    @media only screen and (max-width: 768px) {
        margin-right: 0;
        width: 100%;
        padding: 0 1rem;
      }
`;


const Para = styled.div`
      width: 50%;
      @media only screen and (max-width: 768px) {
        width: 100%;
      }
`;

const T = styled.p`
    color: #E5E5E5;
    font-weight: 500;
    font-size: 1.2rem;
    margin-bottom: 2rem;
    @media only screen and (max-width: 768px) {
        font-weight: 510;
        font-size: 1rem;
        text-align: center;
      }
`;

const Tr = styled.p`
    color: #E5E5E5;
    font-weight: 500;
    font-size: 1.2rem;
    margin-bottom: 2rem;
    text-align: right;
    @media only screen and (max-width: 768px) {
        font-weight: 510;
        font-size: 1rem;
        text-align: center;
      }
`;

const H = styled.h1`
    color: #F3B61F;
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 2rem;
    font-family: 'Montserrat', sans-serif ;
    @media only screen and (max-width: 768px) {
        font-weight: 510;
        font-size: 1.2rem;
        font-weight: bold;
        text-align: center;
      }
`;

const Hr = styled.h1`
    color: #F3B61F;
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 2rem;
    text-align: right;
    font-family: 'Montserrat', sans-serif ;
    @media only screen and (max-width:768px) {
        font-weight: 510;
        font-size: 1.2rem;
        font-weight: bold;
        text-align: center;
      }
`;

const A = styled.a`
    color:#A882DD;
    cursor:pointer;
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 2rem;
    @media only screen and (max-width: 768px) {
        font-weight: 510;
        font-size: 1.2rem;
        font-weight: bold;
        text-align: center;
      }
`;

const Ht = styled.h1`
    color: #E5E5E5;
    font-size: 3rem;
    font-weight: bold;
    margin: 2rem 0;
    font-family: 'Montserrat', sans-serif ;
    @media only screen and (max-width: 768px) {
        font-weight: 510;
        font-size: 1.5rem;
        font-weight: bold;
      }
`;

const Row = styled.div`
      width: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin: 1rem 0;
      @media only screen and (max-width: 768px) {
        flex-direction: column;
        padding: 0 1rem;
      }
`;

const RowReverse = styled.div`
      width: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin: 2rem 0;
      @media only screen and (max-width: 768px) {
        flex-direction: column-reverse;
        padding: 0 1rem;
      }
`;

const SuperPower = () => {
    return (
        <Sec>
            <Ht>Super Power</Ht>
            <Row>
                <Img>
                    <img src={i1} alt="" style={{ width: "100%", height: "100%" }} />\
                </Img>
                <Para>
                    <Hr>THE CRYPTO SUPER POWERS:</Hr>
                    {/* <Tr>5x Rock Pal NFTs randomly airdropped to existing Rock Ral Holders.</Tr>
                    <br /> */}
                    <Tr>
                    We will be releasing 250 rare Kangaroo Heroes that will have a Bitcoin or ETH Chain/T-shirt. If you are a holder you receive exclusive access to our "Virtual Crypto Conference" we will host annually with speakers who will give expert advice and insight on investing in Crypto, Decentralized Finance, tax & accounting, and more.
                    </Tr>


                </Para>
            </Row>
            <RowReverse>
                <Para>
                    <H>The Black Tie Super Powers:</H>
                    <T>We will be releasing 250 rare Kangaroo Heroes that will have a black suit. If you are a holder you receive exclusive access to our "In Person Black Tie Event" we host annually. The black tie event will be hosted on the same weekend as Kangaroo Con but as a separate networking party with notable figures such as Prince Donnell and other influencers/investors. Your network is your net worth. This will be a night to remember.
                    </T>
                </Para>
                <Img>
                    <img src={i2} alt="" style={{ width: "100%", height: "100%" }} />\
                </Img>
            </RowReverse>
            <Row>
                <Img>
                    <img src={i3} alt="" style={{ width: "100%", height: "100%" }} />\
                </Img>
                <Para>
                    <Hr>THE VR SUPER POWERS: </Hr>
                    {/* <Tr>Exclusive Merchandise Store will be launched for all Rock Pal Holders as well as merch giveaways for Rock Pal Holders.
                    </Tr>
                    <br /> */}
                    <Tr>

                    We will be releasing 250 rare Kangaroo Heroes that will have VR Glasses on. If you are a holder you receive exclusive access to an annual "Two Hour Virtual Group Call" with Prince Donnell and team. This is a perfect opportunity for small business owners to ask questions and learn from someone who built one of the fastest growing franchises by the age of 27-years-old.


                    </Tr>
                </Para>
            </Row>

            
            <RowReverse>
                <Para>
                    <H>THE COMIC SUPERPOWERS:</H>
                    {/* <T>Rock Pals will be donating 100 Nintendo switches to Shands Children’s Hospital.</T>
                    <br /> */}
                    <T>We will be releasing 250 rare Kangaroo Heroes that will have  one of three superhero traits such as masks, capes or Cyborg Eyes. If you are a holder you receive a Kangaroo Hero Comic Book airdropped as an NFT every year. You have the ability to hold the NFT as a collectible item or resell it on OpenSea.</T>

                </Para>
                <Img>
                    <img src={i4} alt="" style={{ width: "100%", height: "100%" }} />\
                </Img>
            </RowReverse>
            <Row>
                <Img>
                    <img src={i5} alt="" style={{ width: "100%", height: "100%" }} />\
                </Img>
                <Para>
                    <Hr>THE MERCH COLLECTOR SUPERPOWERS:</Hr>
                    {/* <Tr>In 2022, we will be working with a 3D Printing Company to bring your Rock Pal to life (3D Print). Holders will verify that they own their Rock Pal(s) and will be granted access to purchase their very own 3D Model.</Tr>
                    <Tr>
                        15 ETH will be stored in a community wallet and will act as the Rock Pal Liquidity Pool.
                        Our project will be integrated with Rarity.Tools
                    </Tr> */}
                    <Tr>
                    We will be releasing 250 rare Kangaroo Heroes that will have the official Kangaroo Heroes black or white hoodie as seen below. If you are a holder of a hero with this hoodie, you receive a free Merch Drop each year. This merch drop could include t-shirts, hoodies or even collectible items you can keep or resell.

                    </Tr>
                </Para>
            </Row>
            {/* <A href="/3D">Learn more about the 3D Rock Pals</A> */}
        </Sec>
    )
}

export default SuperPower
