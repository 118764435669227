import React from 'react'
import styled from 'styled-components'
import img from '../../components/Navbar/logo.png'
import Tilt from 'react-parallax-tilt';
import bg from './bgs.png'
import theme from "styled-theming";
import One from './1.png'
import Two from './2.png'
import { motion } from 'framer-motion'
import { T as Tr } from '../Page1/Home'

export const backgroundColor = theme("theme", {
  light: "#000000",
  dark: "#E5E5E5",
});

const Sec = styled.section`
    min-height: 100%;
    display: flex;
    overflow-x: hidden !important;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 3rem 0 0 0 ;
    background-image: radial-gradient(circle, #000000, #0d0d0d, #161617, #1d1d1e, #232526);
    @media only screen and (max-width: 1400px) {
      padding: 3rem 2rem;
  }
    @media only screen and (max-width: 768px) {
       
        padding: 1rem 0 2rem 0;
        flex-direction: column;
        justify-content: space-around;
      }
`;
export const Width = styled.div`
      width: 1600px;
      display: flex;
        
        align-items: center;
        justify-content: space-between;
      @media only screen and (max-width: 1700px) {
        width: 95%;
      }
    @media only screen and (max-width: 1400px) {
        width: 99%;
    }
    @media only screen and (max-width: 1100px) {
        flex-direction: column;
    }

`

const Left = styled.div`
        margin-right: 2rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 50%;
        @media only screen and (max-width: 1100px) {
          
          width: 95%;
      }
    @media only screen and (max-width: 768px) {
        margin-right: 0;
        width: 100%;
        padding: 0 1rem;
        height: auto;
      }
`;

const Right = styled.div`
      margin-left: 2rem;
      display: flex;
      width: 50%;
      @media only screen and (max-width: 1400px) {
        flex-direction: column;
        align-items: center;
    }
    @media only screen and (max-width: 1100px) {
      flex-direction: row;
      width: 95%;
      justify-content: center;
  }
      @media only screen and (max-width: 768px) {
        width: 100%;
        height: auto;
        margin-left: 0;
        padding: 0 1rem;
        flex-direction: column;
        display: none;
      }
`;

const T = styled.p`
    color: #E5E5E5;
    font-weight: 500;
    font-size: 1.3rem;
    margin-bottom: 2rem;
    text-align: left;
    @media only screen and (max-width: 768px) {
        font-weight: 510;
        font-size: 1rem;
       
      }
`;

export const Trr = styled(Tr)`
    font-size: 2.7rem;
    @media only screen and (max-width: 768px) {
      font-size: 2.3rem;
    }
    @media only screen and (max-width: 500px) {
      font-size: 1.6rem;
    }
`;

const Image = styled.div`
      width: 30rem;
      
      position: relative;
    display: flex;
    
    align-items: center;
    justify-content: center;
      @media only screen and (max-width: 1400px) {
        width: 100%;
    }
`
const Image1 = styled(motion.div)`
      width: 30rem;
      height: 27rem;
      @media only screen and (max-width: 1400px) {
        width: 22rem;
        height: 18rem;
    }
    @media only screen and (max-width: 600px) {
      width: 95%;
      height: auto;
  }
`
const Image2 = styled(motion.div)`
      width: 30rem;
      height: 27rem;
      margin: 3rem  0 0 0;
      @media only screen and (max-width: 1400px) {
        width: 22rem;
        height: 18rem;
    }
    @media only screen and (max-width: 600px) {
      width: 95%;
      height: auto;
  }
`

const Page2 = () => {

  return (
    <Sec >

      <Width>

        <Left
          data-aos="fade-right"
        >
          <Image>
            <Trr>
              WELCOME TO
              <br />
              kangaroo HEROS
            </Trr>
            <img src={bg} alt="" style={{ width: "100%", height: "100%" }} />
          </Image>
          <T style={{ marginTop: "2rem" }}>
            Kangaroo Heroes is a collection of 5,000 Kangaroo NFT’s that are unique and live on the Ethereum Blockchain. The Kangaroo Hero is based off the mascot of our company tax & accounting franchise Jumping Jack Taxes. As the Jumping Jack Tax Brand grows across the country, it would create more exposure for our Kangaroo Heroes NFT project.

          </T>
          <T>
            Your Kangaroo Hero grants you access to:
          </T>
          <ol>
            <li style={{ color: "white" }}>
              <T>
                Exclusive Ticket to Kangaroo Con. The largest financial NFT conference in the country. You will only be able to gain access to the conference as an NFT Holder. We will not be selling tickets to the public. We have plans of hosting this conference in December 2022 in Philadelphia, Pa.
              </T>
            </li>
            <li style={{ color: "white" }}>
              <T>
                Rare Super Powers. Of the 4,444 Kangaroo Heroes that are released, a total of 556 Heroes will have specific superpowers which includes access to a private masterclasses on how to invest in crypto currency, limited ticket to our annual black-tie networking event, Group Video Call with the founder Prince Donnell, free airdrop of Kangaroo Heroes financial comic books as NTfs and free merchandise.
              </T>
            </li>
            <li style={{ color: "white" }}>
              <T>
                Discounts through our tax & accounting partner. Get 30% OFF financial services from personal/small business tax prep, bookkeeping, tax planning and consultations. If you're a small business owner, this would give you massive savings in the long term through utilizing our services and having a professional on staff.
              </T>
            </li>
          </ol>
        </Left>

        <Right>

          <Image1
            initial={{ x: 0, y: 0 }}
            animate={{ x: 0, y: -15 }}
            transition={{ duration: 1, repeat: Infinity, repeatType: "reverse" }}
          >
            <img src={One} alt="" style={{ width: "100%", height: "100%" }} />
          </Image1>

          <Image2
            initial={{ x: 0, y: 0 }}
            animate={{ x: 0, y: 15 }}
            transition={{ duration: 1, repeat: Infinity, repeatType: "reverse" }}
          >
            <img src={Two} alt="" style={{ width: "100%", height: "100%" }} />
          </Image2>

        </Right>


      </Width>
    </Sec>
  )
}

export default Page2
