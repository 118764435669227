import React,{useState} from 'react'
import './Content.css'
import styled from 'styled-components'
import { FaAngleDown } from 'react-icons/fa';
import e from '../Page5/E.png'
import { Trr } from '../Page2/Page2';

const Sec = styled.section`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    min-height: 100vh;
    width: 100%;
    overflow-x: hidden !important;
    background-image: radial-gradient(circle, #000000, #0d0d0d, #161617, #1d1d1e, #232526);
    background-size: 100% auto;
    background-repeat: no-repeat;
    flex-direction: column;
    paading: 0 0 3rem 0;
    @media only screen and (max-width: 768px) {
        min-height: 60rem;
        background-size: auto 100% !important;
        width: 100%;
        padding: 1rem 0.5rem 1rem 0.5rem;
        flex-direction: column;
    }

`

const Left = styled.div`
        width: 40vw;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    @media only screen and (max-width: 768px) {
        margin-right: 0;
        width: 100%;
        padding: 0 1rem;
      }
`;

const Right = styled.div`
      width: 40vw;
      display: flex;
        flex-direction: column;
        justify-content: space-between;
      @media only screen and (max-width: 768px) {
        width: 100%;
        padding: 0 1rem;
      }
`;

const T = styled.p`
    color:  #E5E5E5;
    font-weight: 500;
    font-size: 1.5rem;
 
    @media only screen and (max-width: 768px) {
        font-weight: 510;
        font-size: 1rem;
      }
`;

const H = styled.h1`
    color:  #000;
    font-size: 2.5rem;
    font-weight: bold;
    
    @media only screen and (max-width: 768px) {
        font-weight: 510;
        font-size: 1.2rem;
        font-weight: bold;
      }
`;

const Box = styled.div`
    color: #FFFFFF;
    background:linear-gradient(to top, #0f2027, #203a43, #2c5364)   ;
    width: 1300px;
    font-size: 2rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    border-radius: 1rem;
    justify-content: space-between;
    padding: 0.5rem 1.5rem;
    margin: 1rem 0;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
    @media only screen and (max-width: 1500px) {
      width: 95%;
    }
    @media only screen and (max-width: 768px) {
        width: 95%;
      }
`;

const Content = styled.div`
      //  background:linear-gradient(to top, #0f2027, #203a43, #2c5364)   ;
       width: 100%;
       padding: 1rem 2rem ;
       font-size: 1.3rem;
       color: #E5E5E%;
       @media only screen and (max-width: 768px) {
        padding: 1rem;
        font-size: 1rem;
      }
`
const Upper = styled.div`
display: flex;
align-items: center;
      border-radius: 1rem;
    justify-content: space-between;
    padding: 0.5rem 1.5rem;
    width: 100%;
`
const Width  = styled.div`
    width: 1600px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    @media only screen and (max-width: 1700px) {
        width: 93%;
        justify-content: space-between;
    }
    @media only screen and (max-width: 1400px) {
        width: 96%;
        justify-content: space-between;
    }
    @media only screen and (max-width: 1000px) {
        width: 99%;
       
    }
    @media only screen and (max-width: 800px) {
        width: 99%;
        flex-direction: column;
        margin: 10rem  0 0 0;
    }
    @media only screen and (max-width: 600px) {
        width: 99%;
        
        margin: 5rem  0 0 0;
    }
`
const Image = styled.div`
      width: 20rem;
      position: relative;
      display: flex;
      
      align-items: center;
      justify-content: center;
`

const Page6 = () => {

  const [ open1, setOpen1 ] = useState(false);
  const [ open2, setOpen2 ] = useState(false);
  const [ open3, setOpen3 ] = useState(false);

    return (
        <Sec id="roadmap">
            <Width>
              <Image>
                <Trr>
                  FAQ
                </Trr>
                <img src={e} alt="" style={{width:"100%",height:"100%"}} />
              </Image>
             <Box>

                <Upper>
                  <T>What is Kangaroo Heroes NFT?</T>
                  <H><FaAngleDown color="#FFF" onClick={ () => open1 ? setOpen1(false) : setOpen1(true)} style={{cursor:"pointer"}} /></H>
                </Upper>

                <Content className={ open1 ? "show" : "hide" } >

                Kangaroo Heroes is a collection of 5,000 Kangaroo NFT’s that are unique and live on the Ethereum Blockchain. The Kangaroo Hero is based off the mascot of our company tax & accounting franchise Jumping Jack Taxes. As the Jumping Jack Tax Brand grows across the country, it would create more exposure for our Kangaroo Heroes NFT project.<br/>

Your Kangaroo Hero grants you access to: <br/><br/>

Exclusive Ticket to Kangaroo Con. The largest financial NFT conference in the country. You will only be able to gain access to the conference as an NFT Holder. We will not be selling tickets to the public. We have plans of hosting this conference in December 2022 in Philadelphia, Pa. <br/>

Rare Super Powers. Of the 4,444 Kangaroo Heroes that are released, a total of 556 Heroes will have specific superpowers which includes access to a private masterclasses on how to invest in crypto currency, limited ticket to our annual black-tie networking event, Group Video Call with the founder Prince Donnell, free airdrop of Kangaroo Heroes financial comic books as NTfs and free merchandise.<br/><br/>

Discounts through our tax & accounting partner. Get 30% OFF financial services from personal/small business tax prep, bookkeeping, tax planning and consultations. If you're a small business owner, this would give you massive savings in the long term through utilizing our services and having a professional on staff. <br/>
                {/* <div style={{margin:"1rem 0"}}>
                Your Kangaroo Hero grants you access to 30% OFF of our financial services from personal/small business tax prep, bookkeeping, tax planning and consultations. If you're a small business owner, this would give you massive savings in the long term through utilizing our services and having a professional on staff.
                </div>
                <div style={{margin:"1rem 0"}}>
                You will also get exclusive member-only benefits that include Financial Literacy & Skill-Based Events (Virtual) from people who specialize in Real Estate, Accounting, Credit, Stock Market, Crypto and other skill based professions so you can use the knowledge to create financial independence for yourself and family.
                </div>
                Our network of business owners, investors & influencers makes us a great community to join. */}
                </Content>

            </Box>
            <Box>

                <Upper>
                  <T>What makes this project special?</T>
                  <H><FaAngleDown color="#FFF" onClick={ () => open2 ? setOpen2(false) : setOpen2(true)} style={{cursor:"pointer"}} /></H>
                </Upper>

                <Content className={ open2 ? "show" : "hide" } >

                  We're bridging the gap between the Metaverse & Real World Utility. We're not just a collection of art work but a token to access benefits such as discounts on the services you need from personal & small business tax prep, bookkeeping, tax planning & consultations. We're offering 30% off to all holders. The discount is PER WALLET. You cannot double the discounts because you own multiple Kangaroo Heroes. But that leads us into the second reason this project is special. We will be hosting (virtual conferences) where we have speakers in different financial spaces from tax & accounting, real estate, crypto, stock market, etc to educate our community on these skills and how they can make money. 
                </Content>

            </Box>
            <Box>

                <Upper>
                  <T>How can I join the community?</T>
                  <H><FaAngleDown color="#FFF" onClick={ () => open3 ? setOpen3(false) : setOpen3(true)} style={{cursor:"pointer"}} /></H>
                </Upper>

                <Content className={ open3 ? "show" : "hide" } >
                Join our free discord server where you will be able to meet a group of amazing artists, business owners, investors, influencers and more who are striving to create financial freedom.
                </Content>

            </Box>

            {/* <Box>

                <Upper>
                  <T>Traditionally, a text is understood to be a piece</T>
                  <H><FaAngleDown color="#FFF" onClick={ () => open ? setOpen(false) : setOpen(true)} style={{cursor:"pointer"}} /></H>
                </Upper>

                <Content className={ open ? "show" : "hide" } >
                  The Public Sale starts on November 1st, 2021, at 9 PM EST. Price Starts at 0.3 ETH + gas for 6 hours. If the collection is not sold out by then, price drops to 0.25 ETH + gas after the initial 6 hours. If the collection is still not sold out after those 12 hours, the rest of the Fat Apes are sold at 0.2 ETH + gas.
                </Content>

            </Box>
            <Box>

                <Upper>
                  <T>Traditionally, a text is understood to be a piece</T>
                  <H><FaAngleDown color="#FFF" onClick={ () => open ? setOpen(false) : setOpen(true)} style={{cursor:"pointer"}} /></H>
                </Upper>

                <Content className={ open ? "show" : "hide" } >
                  The Public Sale starts on November 1st, 2021, at 9 PM EST. Price Starts at 0.3 ETH + gas for 6 hours. If the collection is not sold out by then, price drops to 0.25 ETH + gas after the initial 6 hours. If the collection is still not sold out after those 12 hours, the rest of the Fat Apes are sold at 0.2 ETH + gas.
                </Content>

            </Box>
            <Box>

                <Upper>
                  <T>Traditionally, a text is understood to be a piece</T>
                  <H><FaAngleDown color="#FFF" onClick={ () => open ? setOpen(false) : setOpen(true)} style={{cursor:"pointer"}} /></H>
                </Upper>

                <Content className={ open ? "show" : "hide" } >
                  The Public Sale starts on November 1st, 2021, at 9 PM EST. Price Starts at 0.3 ETH + gas for 6 hours. If the collection is not sold out by then, price drops to 0.25 ETH + gas after the initial 6 hours. If the collection is still not sold out after those 12 hours, the rest of the Fat Apes are sold at 0.2 ETH + gas.
                </Content>

            </Box> */}
          </Width>
        </Sec>
    )
}

export default Page6
