import React from 'react'
import './Footer.css'
import styled from 'styled-components';
import e from './tele.png'
import l from './logo.png'
import f from './f.png'
import t from './t.png'
import i from './i.png'
import d from './d.png'
import { motion } from 'framer-motion'

const T = styled.p`
    color: #FFF;
    font-weight: 500;
    font-size: 0.9rem;
    margin: 1rem 0;
    @media only screen and (max-width: 768px) {
        font-weight: 510;
        font-size: 1rem;
      }
`;

const Ts = styled.p`
    color: #FFF;
    font-weight: 500;
    font-size: 0.9rem;
    margin: 1rem 0;
    @media only screen and (max-width: 768px) {
        font-weight: 510;
        font-size: 1rem;
        text-align: center;
      }
`;

const H= styled.h1`
    color: #FFF;
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 2rem;
    @media only screen and (max-width: 768px) {
        font-weight: 510;
        font-size: 1.2rem;
        font-weight: bold;
      }
`;

const Sec = styled.footer`
    height: auto;
    width: 100%;
     display: flex;
    align-items: center;
    justify-content: center;
    padding: 4rem 0;
    background:linear-gradient(to top,#0f2027,#203a43,#2c5364);
    @media only screen and (max-width: 1000x) {
        padding: 2rem 0;
       height: auto;
      }
`;

const Input = styled.div`
    width: 22rem;
    height: 3.1rem;
    position: relative;
    @media only screen and (max-width: 400px) {
        width: 99%;
    }
`
const Tele = styled(motion.div)`
      width: 3rem;
      height: 3rem;
      background-color: #ff0000;
      border-radius: 50%;
      display: grid;
      place-items: center;
      position: absolute;
      right:0;
      top: 0;
      cursor: pointer;
`
const Left = styled.div`
    display: grid;
    place-items:center;
`
const Flex = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`
const Icon = styled(motion.div)`
      width: 3rem;
      height: 3rem;
      background-color: #ff0000;
      border-radius: 50%;
      display: grid;
      place-items: center;
      cursor: pointer;
`
const MiddleLogo = styled.div`
    width: 6rem !important;
      height: 6rem !important;
      @media only screen and (max-width: 1000px) {
        
        margin: 1rem 0 2rem 0;
    }
`
const Right = styled.div`
    display: grid;
    place-items:center;
`
const Width  = styled.div`
    width: 1600px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: auto;
    padding: 0 2rem;
    
    @media only screen and (max-width: 1700px) {
        width: 93%;
        justify-content: space-between;
    }
    @media only screen and (max-width: 1400px) {
        width: 96%;
        justify-content: space-between;
    }
    @media only screen and (max-width: 1000px) {
        width: 99%;
        flex-direction: column;
       
        padding: 2rem 0;
    }
    @media only screen and (max-width: 800px) {
        width: 100%;
        
        
    }
    @media only screen and (max-width: 600px) {
   
        justify-content: center;
      
    }
`

const Footer = () => {
    return (
        <Sec>
            <Width>
            <Left>
                {/* <Input>
                    <input type="email" placeholder="Email Address..."  className="input" style={{height:"100%",width:"100%",borderRadius:"2rem"}} />
                    <Tele
                        whileHover={{scale: 1.3}}
                        whileTap={{scale:0.7}}
                    >
                        <img src={e} alt="" style={{width:"1.5rem",height:"1.5rem"}} />
                    </Tele>
                </Input> */}

                <Ts>© 2021 Kangaroo Heroes. All Rights Reserved</Ts>
            </Left>
            <MiddleLogo>
                <img src={l} alt="" style={{width:"100%",height:"100%"}} />
            </MiddleLogo>
            <Right>
                <Flex>
                    {/* <Icon
                        whileHover={{scale: 1.3}}
                        whileTap={{scale:0.7}}
                    >
                        <img src={f} alt="" style={{width:"1.5rem",height:"1.5rem"}} />
                    </Icon> */}
                    <Icon 
                        whileHover={{scale: 1.3}}
                        whileTap={{scale:0.7}}
                        href='https://twitter.com/kangarooheroes' target="_blank"

                    style={{margin:"0 1rem"}}>
                        <img src={t} alt="" style={{width:"1.5rem",height:"1.5rem"}} />
                    </Icon>
                    <Icon
                        whileHover={{scale: 1.3}}
                        whileTap={{scale:0.7}}
                        href='https://www.instagram.com/kangarooheroes/' target="_blank"

                    style={{margin:"0 1rem 0 0"}}>

                        <img src={i} alt="" style={{width:"1.5rem",height:"1.5rem"}} />
                    </Icon>
                    <Icon
                        whileHover={{scale: 1.3}}
                        whileTap={{scale:0.7}}
                        href='https://discord.gg/TRJawAWQNH' target="_blank"

                    >
                        <img src={d} alt="" style={{width:"1.5rem",height:"1.5rem"}} />
                    </Icon>


                    
                </Flex>

                <Ts>
                The royalty fee for Kangaroo Heroes is 10% per sale of a hero on secondary market such as OpenSea.<br/>
Kangaroo Heroes does not come with a license for commercial use.
                </Ts>
            </Right>
            </Width>
        </Sec>
    )
}

export default Footer
